import React from 'react'
import styled from 'styled-components'
import Content from './Content/Content'
import { useStaticQuery, graphql } from 'gatsby'
 
const Wrap = styled.div`
 
`
 
const VideosPage = props => {
  const pageData = useStaticQuery(graphql`
    query {
      gatePage: contentfulGlobal(title: {eq: "Lock Page Content"}) {
        headline
        richCopy1 {
          json
        }
        richCopy2 {
          json
        }
      }
      tab: contentfulAsset(title: {eq: "Tab"}) {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      vidImage: contentfulAsset(title: {eq: "Video Screenshot"}) {
        title
        fluid(maxWidth: 3000, quality: 75) {
          ...GatsbyContentfulFluid
        }  
      }
      vids: allContentfulVideos(sort: {fields: order}) {
        edges {
          node {
            title
            subtitle
            authorName
            order
            length
            copy1 {
              json
            }
            vocabWords {
              word
              definition {
                definition
              }
            }
            videoEmbedLink {
              videoEmbedLink
            }
          }
        }
      }
      whiteArrow: contentfulAsset(title: {eq: "White Arrow"}) {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      blackArrow: contentfulAsset(title: {eq: "Black Arrow"}) {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      nextVidBg: contentfulAsset(title: {eq: "Three Generations of a Family Walking Through a Park"}) {
        title
        fluid(maxWidth: 3000, quality: 50) {
          ...GatsbyContentfulFluid
        }  
      }
    }
  `)
  return (
    <Wrap>
      <Content data={pageData} />
    </Wrap>
  )
}
 
export default VideosPage