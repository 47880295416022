import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PrevNext from './PrevNext'
 
const Wrap = styled.div`
  text-align: center;
  width: 100%;
  position: relative;
  padding: 2rem 2rem 0 2rem;
  color: white;
  .description {
    padding: 1rem 15vw 0 15vw;
  }
  .bg-wrap {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      height: 100%;
      position: relative;
      z-index: 0;
    }
    .dark-overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(133,175,187,0.7);
    }
  }
  @media only screen and (max-width: 991px) {
    .description {
      padding: 1rem 5vw 0 5vw;
    }
  }
`
 
const NextVidPreview = ({ vids, bg, currVid, updateCurrVid, whiteArrow, blackArrow, resetForm }) => {
  const vidArr = vids.edges.map(p => p.node)
  // console.log(vidArr)
  const nextVid = 
    currVid < (vidArr.length - 1)
      ? vidArr[currVid + 1]
      : vidArr[currVid]
  // console.log("nextVid: ",nextVid )
  const { fluid } = bg ? bg : null
  const alt = bg && bg.title ? bg.title : null
  return (
    <Wrap>
      <div className="bg-wrap">
        <div className="dark-overlay" />
        <Img fluid={fluid} alt={alt} />
      </div>
      <h3>{nextVid.title}</h3>
      <div className="description">{nextVid && nextVid.copy1 && documentToReactComponents(nextVid.copy1.json)}</div>
      <Container>
        <PrevNext vidArr={vids.edges.map(p => p.node)} currVid={currVid} updateCurrVid={updateCurrVid} whiteArrow={whiteArrow} blackArrow={blackArrow} resetForm={resetForm} allWhite />
      </Container>      
    </Wrap>
  )
}
 
export default NextVidPreview