import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Content from './Content'
import Download from './Download'
import BookSessionContent from './BookSessionContent'
import Theme from '../../../vars/ThemeOptions'
 
const Wrap = styled.div`
  border-top: 3px solid ${Theme.hex('primary')};
  border-bottom: 3px solid ${Theme.hex('primary')};
  padding: 5rem 0 10rem 0;
  position: relative;
  // z-index: -1;
  overflow: hidden;
`
 
const ShortTermGuardianPage = props => {
  const pageData = useStaticQuery(graphql`
    query {
      content: contentfulGlobal(title: {eq: "Short Term Guardian"}) {
        title
        headline
        richCopy1 {
          json
        }
        richCopy2 {
          json
        }
      }
      bullet: contentfulAsset(title: {eq: "Custom Bullet"}) {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      pdf: contentfulAsset(title: {eq: "Short Term Guardian PDF"}) {
        title
        file {
          url
        }
      }
      pdfPreview: contentfulAsset(title: {eq: "Short Term Guardian PDF Preview"}) {
        title
        fluid(maxWidth: 2000, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)
  return (
    <Wrap>
      <Container>
        <Row>
          <Col sm={{span: 12, order: 2}} lg={{span: 7, order: 1}}>
            <Content data={pageData.content} bullet={pageData.bullet} />
          </Col>
          <Col sm={{span: 12, order: 3}} lg={{span: 1, order: 2}} />
          <Col sm={{span: 12, order: 2}} lg={{span: 4, order: 3}}>
            <Download data={pageData.pdf} preview={pageData.pdfPreview} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={8}>
            <BookSessionContent data={pageData.content} />
          </Col>
        </Row>
      </Container>
    </Wrap>
  )
}
 
export default ShortTermGuardianPage