import React from 'react'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types';
import Theme from '../../../vars/ThemeOptions'
import ListBullet from '../../../testing/CustomListBullet';
import Bubble from '../../../testing/Bubble';

 
const Wrap = styled.div`
  position: relative;
  h2 {
    font-weight: 300;
    color: #8BC7D2;
    margin-bottom: 0;
  }
  h1 {
    font-weight: 800;
    color: ${Theme.hex('primary')};
    margin-bottom: 2rem;
  }
  p {
    font-weight: 200;
  }
  h5:last-of-type {
    margin: 1.5rem 0 1rem 4rem;
  }
  .content-list {
    .item {
      margin: 0 0 -.5rem 4rem;
      display: flex;
      .bullet-wrap {
        width: 2rem;
        height: 2rem;
        margin-right: .7rem;
      }
    }
  }
  .bg-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`
 
const STGContent = ({ data }) => {
  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node) => {
        const { content } = node;
        // console.log("content in rtoptions: ", content)        
        return (
          content && (
            <div className="content-list">
              {content.map((item, i) => {
                // console.log("item: ", item)
                return (
                  <div className="item">
                    <div className="bullet-wrap">
                      <ListBullet />
                    </div>
                    <p>
                      {item.content[0].content[0].value}
                    </p>                    
                  </div>
                )
              })}
            </div>
          )
        )
      }
    }
  }
  return (
    <Wrap>
      <h2>{data.headline}</h2>
      <h1>{data.title}</h1>
      {documentToReactComponents(data.richCopy1.json, options)}
      <div className="bg-wrap" >
        <Bubble size={`45rem`} top={`-25%`} left={`120%`} bg={Theme.hex('secondary')} zIndex={11} />
        <Bubble size={`20rem`} top={`-25%`} left={`110%`} bg={Theme.hex('primary')} zIndex={10} />
        <Bubble size={`25rem`} top={`100%`} left={`-75%`} bg={Theme.hex('secondary')} zIndex={-1} />
        <Bubble size={`10rem`} top={`150%`} left={`-25%`} bg={Theme.hex('primary')} />
      </div>
    </Wrap>
  )
}
 
export default STGContent