import React from 'react'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Container, Row, Col } from 'react-bootstrap'
import Theme from '../../../../../vars/ThemeOptions'
import { WMKLink } from 'wmk-lib'
 
const Wrap = styled.div`
  margin-top: 5rem;
  padding: 0 7vw;
  position: relative;
  h4 {
    margin-left: -30px;
    margin-bottom: 1rem;
    span {
      font-weight: 200;
    }
  }
  .vocab-row {
    width: 100%;
    display: flex;
    :first-of-type {
      margin-top: 2rem;
    }
    :last-of-type {
      margin-bottom: 2rem;
    }
    p {
      &.word {
        color: #F15C11;
      }
      &.def {
        font-weight: 200;
      }
    }
  }
  .stg-link-wrap {
    padding: 2rem 0;
  }
  .bubble-wrap {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    .bubble {
      border-radius: 50%;
      width: 50vw;
      height: 50vw;
      position: absolute;
      z-index: 20;
      &.orange {
        background: ${Theme.hex('primary')};
        left: -60vw;
        top: -35vw;
      }
      &.blue {
        background: ${Theme.hex('secondary')};
        right: -45vw;
        top: 0vw;
      }
    }
  }
`
 
const VidInfo = ({ vid }) => {
  console.log("vid in info: ", vid)
  console.log("is stg a vocab word for vid? - ", vid.subtitle === "Short Term Guardianship")
  return (
    <Wrap>
      <h4>{vid.title + `: `}<span>{vid.subtitle}</span></h4>
      {vid.copy1 && documentToReactComponents(vid.copy1.json)}
      <Container>
        {vid.vocabWords &&
          vid.vocabWords.map((word, i) => {
            return(
              <Row key={word.word + i} className="vocab-row">
                <Col xs={4} lg={2}>
                  <p className="word">{word.word + `:`}</p>
                </Col>
                <Col xs={8} lg={10}>
                  <p className="def">{word.definition.definition}</p>
                </Col>
              </Row>
            )
          })
        }
        {vid.subtitle === "Short Term Guardianship" &&
          <Row>
            <Col>          
              <div className="stg-link-wrap">
                <p>Start your short term guardianship <WMKLink to={`/short-term-guardian`} target="blank" >here</WMKLink>.</p>
              </div>      
            </Col>
          </Row>
        }
      </Container>
      <div className="bubble-wrap">
        <div className="bubble orange" />
        <div className="bubble blue" />
      </div>
    </Wrap>
  )
}
 
export default VidInfo