import React from 'react'
import styled from 'styled-components'
import Theme from '../../../../../../vars/ThemeOptions'
 
const CircleDiv = styled.div`
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;  
  height: 3rem;
  min-height: 3rem;
  max-height: 3rem;  
  border-radius: 50%;
  background: ${Theme.hex('primary')};
  margin: 0 2rem 1rem 1rem;
`
 
const Circle = ({ color }) => {
  let bgColor = "";
  switch (color) {
    case "primary":
      bgColor = Theme.hex('primary');
      break;
    case "secondary":
      bgColor = Theme.hex('secondary');
      break;
    case "accent":
      bgColor = Theme.hex('accent');
      break;
    default:
      break;
  }
  return (
    <CircleDiv style={{background: bgColor}} />
  )
}
 
export default Circle