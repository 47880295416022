import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import Theme from "../../../vars/ThemeOptions";
import get from "lodash/get";

const Wrap = styled(Col)`
  h6 {
    color: ${Theme.hex("primary")};
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 30px;
  }
  margin-bottom: 10rem;
  @media only screen and (max-width: 1199px) {
    // margin-bottom: 45rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Row2Col1 = ({ richText }) => {
  const [dataLayerObj, setDataLayerObj] = useState();
  useEffect(() => {
    const globalDataLayer = window.dataLayer;
    if (globalDataLayer && typeof globalDataLayer === "object") {
      setDataLayerObj(globalDataLayer);
    }
  }, [richText]);
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        console.log("INLINES.HYPERLINK node: ", node);
        const linkUrl = get(node.data, "uri");
        const linkText = get(node.content[0], "value");
        return (
          <a
            href={linkUrl}
            onClick={
              dataLayerObj
                ? () => {
                    dataLayerObj.push({
                      event: "pdfDownload",
                      pdfType: "momToMom",
                    });
                  }
                : undefined
            }
          >
            {linkText}
          </a>
        );
      },
    },
  };
  return (
    <Wrap lg={12} xl={5}>
      {documentToReactComponents(richText.json, options)}
    </Wrap>
  );
};

export default Row2Col1;
