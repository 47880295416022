import React from 'react'
import styled from 'styled-components'
import { IoMdArrowDropright } from 'react-icons/io'
import Theme from '../../../../../vars/ThemeOptions'
 
const Wrap = styled.div`
  width: 100%;  
  padding-top: 3.5rem;
  position: relative;
  .vid-row {
    position: relative;
    button {
      width: 100%;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      position: relative;
      margin: 5px 0 5px 2rem;
      p {
        font-size: .9rem;
        font-weight: 200;
        margin: 0;
        :first-of-type {
          text-align: left;
          width: 75%;
        }
        :last-of-type {
          text-align: right;
          width: 20%;
        }
      }
      &.active {
        background: ${Theme.hex('primary')};
        color: white;
      }
    }
    .arrow {
      position: absolute;
      top: -.5rem;
      left: 0;
      transform: scaleX(1.5);
      font-size: 1.5rem;
      color: ${Theme.hex('primary')};
    }
  }
`
 
const VidList = ({ vids, currVid, setCurrVid }) => {
  return (
    <Wrap>
      {vids.map((vid, i) => {
        return(
          <div className="vid-row" key={vid.title + i}>
            <button onClick={() => setCurrVid(i)} className={currVid === vid.order ? "active" : ""}>
              <p>{(vid.order + 1) + `. ` + vid.title}</p>
              <p>{vid.length}</p>
            </button>
            {currVid === i && 
              <div className="arrow">
                <IoMdArrowDropright />
              </div>  
            }
          </div>
        )
      })}
    </Wrap>
  )
}
 
export default VidList