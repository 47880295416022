import React, { useState } from "react";
// import styled from 'styled-components'
import Videos from "./Videos/Videos";
import Gate from "./Gate/Gate";
import { HubUserContext, isValidUser } from "../../../context/HubspotContext";

// const Wrap = styled.div`

// `

const Content = ({ data }) => {
  const [unlocked, setUnlocked] = useState(false);
  return unlocked ? (
    // <HubUserContext.Consumer>
    //   {({ user }) => {
    //     //console.log(user);
    // return isValidUser(user) || unlocked
    <>
      <Videos
        vids={data.vids}
        whiteArrow={data.whiteArrow}
        blackArrow={data.blackArrow}
        nextVidBg={data.nextVidBg}
        session={data.bookSession}
        tab={data.tab}
      />
    </>
  ) : (
    <>
      <Gate
        data={data.gatePage}
        vidImage={data.vidImage}
        setUnlocked={setUnlocked}
      />
    </>

    //     }}
    // </HubUserContext.Consumer>
  );
};

export default Content;

// storing old attempts in case we need them
// const withoutContext = `
// <Wrap>
// {unlocked
//   ? <Videos vids={data.vids} whiteArrow={data.whiteArrow} blackArrow={data.blackArrow} nextVidBg={data.nextVidBg} session={data.bookSession} />
//   : <Gate data={data.gatePage} vidImage={data.vidImage} />
// }
// </Wrap>
// `

// const withContext = `
// <HubUserContext.Consumer>
// {({ user }) => {
//   console.log(user);
//   return isValidUser(user)
//     ? <Videos vids={data.vids} whiteArrow={data.whiteArrow} blackArrow={data.blackArrow} nextVidBg={data.nextVidBg} session={data.bookSession} tab={data.tab} />
//     : <Gate data={data.gatePage} vidImage={data.vidImage} />

//   }}
// </HubUserContext.Consumer>
// `

// const otherWithContext = `
// <HubUserContext.Consumer>
// {(values) => {
//   const { user } = values ? values : { user: null };
//   console.log("values in Content.js: ", values)
//   return isValidUser(user)
//       ? <Videos vids={data.vids} whiteArrow={data.whiteArrow} blackArrow={data.blackArrow} nextVidBg={data.nextVidBg} session={data.bookSession} />
//       : <Gate data={data.gatePage} vidImage={data.vidImage} />

// }}
// </HubUserContext.Consumer>
// `
