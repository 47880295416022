import React from 'react'
import axios from '../../../../../axios'
import styled from 'styled-components'
import Theme from '../../../../../../vars/ThemeOptions'
import Circle from './Circle'

 
const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  .form-wrap {
    width: 100%;
    position: relative;
    input[type="text"], textarea {
      width: 95%;
      border: 2px solid ${Theme.hex('secondary')};   
      color: ${Theme.hex('accent')};
      ::placeholder {
        color: ${Theme.hex('accent')};
        font-style: italic;
        font-weight: 300;
      }
      :focus {
        outline: none;
      }
    }
    input[type="text"] {
      margin-bottom: 10px;
      padding: 5px 10px;
    }
    textarea {
      padding: 5px 10px 20px 10px;
    }
    input[type="submit"] {
      display: block;
      position: relative;
      top: 4px;
      left: 82%;
      width: 13%;
      padding: 7px 0;
      border: none;
      background: ${Theme.hex('accent')};
      color: white;
      font-size: 1.3rem;
    }
    .err {
      color: red;
      &.big {
        width: 90%;
        text-align: center;
        font-size: 1.2rem;
        margin-top: 1rem;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    input[type="submit"] {
      left: 72% !important;
      width: 23% !important;
      padding: 7px 0;
    }
  }
`
 
const CommentForm = ({ currVid, subject, setSubject, comment, setComment, profaneSub, setProfaneSub, profaneCom, setProfaneCom, success, setSuccess, submitErr, setSubmitErr, resetForm }) => {

  const handleSubChange = (e) => {
    setSubject(e.target.value)
    return(
      checkSub(e.target.value)
    )
  }
  const handleComChange = (e) => {
    setComment(e.target.value)
    return(
      checkCom(e.target.value)
    )
  }
  const checkSub = (sub) => {
    return (
      filter.isProfane(sub)
        ? setProfaneSub(true)
        : setProfaneSub(false)
    )
  } 
  const checkCom = (com) => {
    return (
      filter.isProfane(com)
        ? setProfaneCom(true)
        : setProfaneCom(false)
    )
  } 
  var Filter = require('bad-words')
  const filter = new Filter()

  const formSuccess = (object) => {
    setSuccess(true)
    axios.post(`/vid-`+currVid+`-comments.json`, object)
    return (
      resetForm(true)
    )
  }

  const submitForm = (event) => {
    event.preventDefault()
    const submitData = {
      subject: subject,
      comment: comment,
      date: new Date()
    }
    return (
      profaneSub || profaneCom || subject.length === 0 || comment.length === 0
        ? setSubmitErr(true)
        : formSuccess(submitData)
    )
  }

  
  return (
    <>
      {success 
        ? <h3 style={{textAlign: 'center', marginBottom: '2rem'}}>Thanks for your comment! Kim will reply as soon as possible.</h3>  
        : null
      }
      <Wrap>
        <Circle />
        <div className="form-wrap">        
            <form onSubmit={(event) => submitForm(event)}>
              <input type="text" name="subject" placeholder="Subject" value={subject} onChange={(e) => handleSubChange(e)} />
              {profaneSub && <p className="err">Profanity detected in subject. Please edit your subject before submitting.</p>}
              <textarea name="comment" placeholder="Ask Kim a question related to the video." value={comment} onChange={(e) => handleComChange(e)}  />
              {profaneCom && <p className="err">Profanity detected in comment. Please edit your comment before submitting.</p>}
              <input type="submit" value="Post" />
              {submitErr && <p className="err big">Whoops, we were not able to post your comment. Please make sure that you filled out both the subject and the comment and did not use any profanity, then try to post again.</p> }
            </form>
        </div>
      </Wrap>
    </>
  )
}
 
export default CommentForm