import * as React from 'react';
import { useState } from 'react';
import get from 'lodash/get'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Theme from '../../../vars/ThemeOptions';
import { colors } from '../../../vars/palette';
import Bubble from '../../../testing/Bubble';
 
const Wrap = styled.div`
  position: relative;
  width: 100vw;
  height: 150vw;
  max-height: 50rem;
  min-height: 45rem;
  padding: 7rem 0;
  text-align: center;
  h3 {
    color: ${Theme.hex("darkSecondary")};
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  p {
    max-width: 30rem;
    margin: auto;
    line-height: 2rem;
  }
  @media only screen and (max-width: 991px) {
    text-align: left;
    h3, p {
      max-width: 24rem;
      margin: auto;
      margin-top: 1rem;
    }
  }
  @media only screen and (max-width: 575px) {
    padding: 4rem 1.5rem;
  }
`
const BgWrap = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 575px) {
    left: -10vw;
    img {
      width: 120vw;
    }
  }
`
const PersonasWrap = styled.div`
  padding: 2rem;
  @media only screen and (max-width: 575px) {
    padding: 1rem;
  }
`
const PersonaRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`
const PersonaButton = styled.button`
  background: none;
  border: none;
  width: 5rem;
  img {
    width: 5rem;
  }
  margin: 0 .5rem;
  ${({ row, order }) => row === 1 && order === 2 && `margin-right: 7rem;`}
  transform: scale(1);
  transition: all .3s ease;
  :hover {
    transform: scale(1.1);
    transition: all .3s ease;
  }
  @media only screen and (max-width: 575px) {
    width: 4rem;
    img {
      width: 4rem;
    }
    margin: 0 5vw;
  }
  @media only screen and (max-width: 350px) {
    width: 20vw;
    img {
      width: 20vw;
    }
    margin: 0 1vw;
  }
`
const InfoWrapButton = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`
const imageOffset = "-8.5rem";
const PersonaCard = styled.div`
  position: relative;
  width: 25rem;
  max-width: 95vw;
  max-height: 85vh;
  padding: 3rem 3rem 2rem;
  border-radius: 15px;
  background: ${colors.hex('white')};
  text-align: left;
  margin-bottom: -2rem;
  img {
    position: relative;
    top: ${imageOffset};
    margin-bottom: ${imageOffset};
  }
  h5 {
    margin-top: -1rem;
    color: ${colors.hex('primary')};
    font-weight: 700;
    letter-spacing: 1px;
  }
  h6 {
    color: ${colors.hex('secondary')};
    font-weight: 700;
    letter-spacing: 1px;
    margin: 1rem 0 .5rem;
  }
`
const CopyWindow = styled.div`
  position: relative;
  max-height: 8.2rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5px 1rem 0;
  p {
    line-height: 1.5rem;
    margin-top: 0;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.hex('secondary')};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
// const CopyGradient = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: linear-gradient(to top, rgba(254, 254, 254, 1) 0%, rgba(254, 254, 254, 0) 5%);
// `

const BubbleWrap = styled.div`
  position: relative;
  left: 60%;
  bottom: 0%;
  width: 0; 
  height: 0;
`
 
const InteractiveGraphic = ({ personas, imageTree, copyJson }) => {
  const [currPers, setCurrPers] = useState(null);
  personas && personas.sort((a,b) => a.text1 === b.text1 ? a.text2 - b.text2 : a.text1 - b.text1) // text1 = row, text2 = order in row, so this sorts by row and then by order in row
  const personasByRow = [];
  for (let i = 1; i < 10; i++) {
    let rowArr = [];
    personas && personas.forEach(pers => parseInt(pers.text1) === i ? rowArr.push(pers) : null);
    if (rowArr.length > 0) {personasByRow.push(rowArr)}
  }
  return (
    <Wrap>
      {copyJson && documentToReactComponents(copyJson)}
      <PersonasWrap>
        {personasByRow && personasByRow.map((row, i) => {
          return (
            <PersonaRow row={i+1}>
              {row.map((pers, j) => {
                const image = pers.files[0];
                return (
                  <PersonaButton onClick={() => setCurrPers(pers)} row={i+1} order={j+1}>
                    <img src={get(image, "file.url")} alt={get(image, "title")} />
                  </PersonaButton>
                )
              })}
            </PersonaRow>
          )
        })}
      </PersonasWrap>
      <BgWrap>
        <img src={get(imageTree, "file.url")} alt={get(imageTree, "title")} />
      </BgWrap>
      {currPers && 
        <InfoWrapButton onClick={() => setCurrPers(null)}>
          <PersonaCard>
            <img src={get(currPers, "files[0].file.url")} alt={get(currPers, "files[0].title")} />
            <h5>{get(currPers, "title").split(" - ")[2]}</h5>
            <h6>Background</h6>
            <CopyWindow>
              {documentToReactComponents(get(currPers, "richCopy1.json"))}
              {/* <CopyGradient /> */}
            </CopyWindow>
            <h6>Milestone Events</h6>
            <CopyWindow>
              {documentToReactComponents(get(currPers, "richCopy2.json"))}
              {/* <CopyGradient /> */}
            </CopyWindow>
          </PersonaCard>
        </InfoWrapButton>
      }
      <BubbleWrap>
        <Bubble size="6rem" top="6rem" left="0" bg={Theme.hex('darkSecondary')} mobBreak="991px" mobSize="4rem" mobTop="-2rem" mobLeft="7rem" />
        <Bubble size="4rem" top="6rem" left="4rem" bg={colors.hex('primary')} mobBreak="991px" mobSize="2rem" mobTop="-2rem" mobLeft="9.5rem" />
      </BubbleWrap>
    </Wrap>
  )
}
 
export default InteractiveGraphic