import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
 
const Wrap = styled.div`
  background: #F15C11;
  padding: 2px 0 4px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .copy {
    position: relative;
    z-index: 2;
    justify-content: center;
    background: rgba(63,136,171, .9);
    color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    h1 {
      text-align: center;
      font-weight: 600;
    }
  }
  .click-down {
    position: absolute;
    z-index: 1;
    bottom: -65px;
    a {
      display: block;
      width: 150px;
      height: 150px;
      padding-bottom: 30px;
      border-radius: 50%;
      background: #F15C11;
      color: white;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      text-decoration: underline;
      p {
        margin: 0;
        text-align: center;
        font-size: 14px;
      }
    }
  }
`
 
const Intro = ({ headline, copy }) => {
  return (
    <Wrap>
      <div className="content">
        <Container fluid className="copy">
          <Row>
            <Col sm={0} md={3} />
            <Col sm={12} md={6}>
              <h1>{headline}</h1>
              {documentToReactComponents(copy.json)}
            </Col>
            <Col sm={0} md={3} />
          </Row>
        </Container>
        <div className="background-img">

        </div>
      </div>
      <div className="click-down">
        <a href="#form-row">
          <p>Request Access</p>
        </a>
      </div>
    </Wrap>
  )
}
 
export default Intro