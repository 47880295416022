import React from 'react'
import styled from 'styled-components'
import Intro from './Intro'
import Form from './Form'
 
const Wrap = styled.div`
 
`
 
const Gate = ({ data, vidImage, setUnlocked }) => {
  // console.log("data in Gate.js: ", data)
  return (
    <Wrap>
      <Intro headline={data.headline} copy={data.richCopy1} />
      <Form copy={data.richCopy2} vidImage={vidImage} setUnlocked={setUnlocked} />
    </Wrap>
  )
}
 
export default Gate