import React from 'react'
import styled from 'styled-components'
import HubspotForm from 'react-hubspot-form'
import Theme from '../../../../../vars/ThemeOptions'
 
const Wrap = styled.div`
  width: 100%;
  position: relative;
  margin-top: .5rem;
  h2 {
    color: ${Theme.hex('primary')} !important;
    font-size: 1.5rem !important;
    font-weight: 300 !important;
    letter-spacing: 1px !important;
    margin-bottom: 0 !important;
  }
  form {
    margin-top: .5rem;
    width: 100%;
    display: flex;
    position: relative;
    .field {
      width: 82%;      
      .input {
        width: 100%;
        position: relative;
        top: -25px;
        input {
          border: 2px solid ${Theme.hex('secondary')};
          padding: 3px 10px;
          width: 100%;
          color: ${Theme.hex('accent')};
          ::placeholder {
            color: ${Theme.hex('secondary')};
            font-style: italic;
            font-weight: 300;
          }
          :focus {
            outline: none !important;
          }
        }
      }
    }
    .hs-submit {
      position: absolute;
      right: 0;
      top: -1px;
      input {
        background: ${Theme.hex('primary')};
        border: none;
        color: white;
        padding: 5px 2rem;
      }
    }
    span.hs-form-required, ul.hs-error-msgs {
      display: none !important;
    }
  }
`
 
const BookSession = ({ session }) => {
  // const embedCode = session && session.embedCode && session.embedCode.longText && session.embedCode.longText
  // const html = {__html: embedCode}
  return (
    <Wrap>   
      <h2>Book a session with Kim!</h2> 
      <HubspotForm
        portalId='7210399'
        formId='7ec18f9d-77a3-4f7f-b06e-0c5bfa1e6cc1'
        onSubmit={() => console.log('Submit!')}
        onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading...</div>}
        />
    </Wrap>
  )
}
 
export default BookSession