import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import Theme from '../../../../../vars/ThemeOptions'
 
const WrapRow = styled(Row)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border: none;
    &.prev {
      background: none;
      padding: 10px 0 10px 25px;
      white-space: nowrap;
      .white {
        color: white;
      }
    }
    &.next {
      background: ${Theme.hex('primary')};
      color: white;
      padding: 10px 25px 10px 0;
      white-space: nowrap;
    }
    .arrow-wrap {
      width: 3rem;
      height: 1rem;
    }
    .text-wrap {
      p {
        margin: 0 15px;
        white-space: nowrap;
      }
    }
  }
  .centered {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 767px) {
    .text-wrap {
      p {
        margin: 0;
        font-size: .8rem;
      }
    }
  }
`
 
const PrevNext = ({ vidArr, currVid, updateCurrVid, blackArrow, whiteArrow, resetForm, allWhite }) => {
  const blackArrFluid = blackArrow && blackArrow.fluid ? blackArrow.fluid : null
  const blackArrAlt = blackArrow && blackArrow.title ? blackArrow.title : null
  const whiteArrFluid = whiteArrow && whiteArrow.fluid ? whiteArrow.fluid : null
  const whiteArrAlt = whiteArrow && whiteArrow.title ? whiteArrow.title : null
  const nextPrevVid = (vidInd, prevNext) => {
    updateCurrVid(vidInd, prevNext)
    resetForm()
  }
  return (
    <WrapRow>
      <Col xs={12} lg={3} />
      <Col xs={6} lg={3} className="centered">
        {currVid > 0 &&
          <button onClick={() => nextPrevVid(currVid, "prev")} className="prev">
            <div className="arrow-wrap">
              {allWhite 
                ? <Img fluid={whiteArrFluid} alt={whiteArrAlt} style={{transform: `rotate(180deg)`}} />
                : <Img fluid={blackArrFluid} alt={blackArrAlt} />
              } 
            </div>
            <div className="text-wrap">
              <p className={allWhite ? "white" : ""}>PREVIOUS VIDEO</p>
            </div>          
          </button>
        }
      </Col>
      <Col xs={6} lg={3} className="centered">
        {currVid < (vidArr.length - 1) && 
          <button onClick={() => nextPrevVid(currVid, "next")} className="next">
            <div className="text-wrap">
              <p>NEXT VIDEO</p>
            </div>  
            <div className="arrow-wrap">
              <Img fluid={whiteArrFluid} alt={whiteArrAlt} /> 
            </div>
          </button>
        }
      </Col>
      <Col xs={12} lg={3} />
    </WrapRow>
  )
}
 
export default PrevNext