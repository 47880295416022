import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Theme from '../../../vars/ThemeOptions'
 
const Wrap = styled.div`
  padding: 2rem 10vw;
  border-top: 3px solid ${Theme.hex('tertiary')};
  // border-bottom: 3px solid #F15C11;
  position: relative;
  background: white;
  a {
    .gatsby-image-wrapper {
      width: 261px !important;
      height: 63px !important;
    }
    &.phone {
      position: absolute;
      top: 0;
      right: 20vw;
      // background: ${Theme.hex('accent')};
      color: white;
      padding: 3px 25px 12px 25px;
      transition: color .3s ease;
      .tab-bg {
        position: absolute;
        top: -6px;
        left: -25px;
      }
      p:last-of-type {
        color: #22749B;
      }
      :hover {
        text-decoration: none;
        p:last-of-type {
          color: white;
          transition: color .3s ease;
        }
      }
      p {
        position: relative;
        z-index: 10;
        margin-bottom: -7px;
        text-align: center;
        font-weight: bold;
        :first-of-type {

        }
        :last-of-type {
          font-size: 1.5rem;
          transition: color .3s ease;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    a.phone {
      right: 4vw;
      padding: 3px 15px 5px 15px;
      p {
        margin-bottom: 0px;
        :last-of-type {
          font-size: 1rem;
        }
      }
    }
  }
`
 
const Header = ({ logo, tab }) => {
  const {fixed} = logo ? logo : null
  const {fluid} = tab ? tab : null
  return (
    <Wrap>
      <a href="https://www.willsandwellness.com" target="_blank" rel="noreferrer">
        <Img fixed={fixed} alt="Wills and Welness logo" />
      </a>
      <a className="phone" href="tel:7202668190">
        <div className="tab-bg">
          <Img fluid={fluid} alt={tab.title} />
        </div>        
        <p>Call Us Today!</p>
        <p>720.266.8190</p>
      </a>
    </Wrap>
  )
}
 
export default Header