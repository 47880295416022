import React from 'react'
import styled from 'styled-components'
import { FaFacebookSquare, FaYoutube, FaGooglePlusG, FaYelp } from 'react-icons/fa'
import Theme from '../../../vars/ThemeOptions'
 
const Wrap = styled.div`
  .blue {
    padding: 15px 10vw;
    height: 8rem;
    background: #4780A4;
    display: flex;
    a {
      background: #929292;
      color: white;
      font-size: 2rem;
      width: 3rem;
      height: 3rem;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background .3s ease;
      :hover {
        background: #7a7a7a;
        transition: background .3s ease;
      }
    }
  }
  .black {
    background: ${Theme.hex('nearBlack')};
    height: 4rem;
  }
`
 
const Footer = props => {
  return (
    <Wrap>
      <div className="blue">
        <a href="https://www.facebook.com/willsandwellness" target="_blank" rel="noreferrer" >
          <FaFacebookSquare />
        </a>   
        <a href="https://www.youtube.com/channel/UCCWod5xoQAgA_6oAhM2If3Q/videos" target="_blank" rel="noreferrer" >
          <FaYoutube />
        </a>   
        <a href="https://www.google.com/search?q=wills+and+wellness&rlz=1C1CHBF_enUS777US777&oq=wills+and+wellness+&aqs=chrome..69i57j69i60l3j69i59j0.12134j0j7&sourceid=chrome&ie=UTF-8#lrd=0x876c7894e0c46371:0xe89dd2bc1e9a680,1,,," target="_blank" rel="noreferrer" >
          <FaGooglePlusG />
        </a>   
        <a href="https://www.yelp.com/biz/wills-and-wellness-estate-planning-denver-2" target="_blank" rel="noreferrer" >
          <FaYelp />
        </a>   
      </div>
      <div className="black" />
    </Wrap>
  )
}
 
export default Footer