import React from 'react'
import styled from 'styled-components'
import { Container, Row } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import Theme from '../../../vars/ThemeOptions'
import Row1Col1 from './R1C1'
import Row1Col2 from './R1C2'
import Row2Col1 from './R2C1'
import Bubble from '../../../testing/Bubble'
 
const Wrap = styled.div`
  border-top: 3px solid ${Theme.hex('primary')};
  border-bottom: 3px solid ${Theme.hex('primary')};
  padding: 2rem 0 0 0;
  position: relative;
  // z-index: -1;
  overflow: hidden;
  a {
    display: block;
    text-align: center;
    background: ${Theme.hex('primary')};
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    width: 10rem;
    padding: .5rem 0;
    border-radius: 20px;
    transform: scale(1);
    transition: all .3s ease;
    :hover {
      text-decoration: none;
      transform: scale(1.1);
      transition: all .3s ease;
    }
  }
  .container, .container-fluid {
    position: relative;
  }
`

const Row1 = styled(Row)`
  margin-bottom: 10rem;
  @media only screen and (max-width: 1199px) {
    margin-bottom: 3rem;
  }
`

const Row2 = styled(Row)`

`

const BgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
`
 
const ShortTermGuardianPage = props => {
  const pageData = useStaticQuery(graphql`
    query {
      content: contentfulGlobal(title: {eq: "Mom-To-Mom Toolkit"}) {
        title
        richCopy1 {
          json
        }
        richCopy2 {
          json
        }
        text1
        text2
        files {
          title
          fluid(maxWidth: 2000, quality: 50) {
            ...GatsbyContentfulFluid
          }
          file {
            url
          }
        }
      }
      bullet: contentfulAsset(title: {eq: "Custom Bullet"}) {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)
  // console.log('pageData from Toolkit: ', pageData);
  const richText1 = get(pageData.content, "richCopy1");
  const richText2 = get(pageData.content, "richCopy2");
  const files = get(pageData.content, "files");
  const image1 = files && files[0] ? files[0] : null;
  const image2 = files && files[1] ? files[1] : null;
  const toolkitLink = get(pageData.content, "text1");
  const toolkitText = get(pageData.content, "text2");

  return (
    <Wrap>
      <Container>
        <Row1>
          <Row1Col1 richText={richText1} />
          <Row1Col2 image={image1} link={toolkitLink} linkText={toolkitText} />
        </Row1>
        <Row2>
          <Row2Col1 richText={richText2} />
        </Row2> 
        <BgWrap>
          {/* big blue bubble */}
          <Bubble 
            size="75rem"
            top="-39rem"
            left="-33rem"
            bg={Theme.hex('secondary')} 
            zIndex={1}
            mobBreak="1199px"
            mobSize="120rem"
            mobTop="-94rem"
            mobLeft="-45rem" 
          />
          {/* small orange bubble */}
          <Bubble 
            size="20rem"
            top="25rem"
            left="-20rem"
            bg={Theme.hex('primary')} 
            zIndex={2} 
            mobBreak="1199px"
            mobTop="18rem" 
            mobLeft="80vw"   
          />
          {/* mother daughter image bubble */}
          <Bubble
            size="60rem"
            top="24rem"
            left="35rem"
            bgType="image"
            bg={image2.file.url}
            overlay="linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,.95) 20%, rgba(255,255,255,.25) 40%, rgba(255,255,255,0) 60%)"
            mobBreak="1199px"
            mobSize="0"
            mobTop="80rem"
            mobLeft="-5vw" 
            mobOverlay="linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,.95) 25%, rgba(255,255,255,.25) 80%, rgba(255,255,255,0) 100%)" 
          />
          {/* second big blue bubble (only for mobile size) - currently commented out because it was a fucking nightmare to try and keep it the correct size on mobile while avoiding excessive overlap with other pieces */}
          {/* <Bubble 
            size="0rem"
            top="0rem"
            left="0rem"
            bg={Theme.hex('secondary')} 
            zIndex={1}
            mobBreak="1199px"
            mobSize="65rem"
            mobTop="43rem"
            mobLeft="-5rem" 
          /> */}
        </BgWrap>
      </Container>
    </Wrap>
  )
}
 
export default ShortTermGuardianPage