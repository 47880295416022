import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import Theme from "../../../vars/ThemeOptions";
import { BLOCKS } from '@contentful/rich-text-types';
import ListBullet from "../../../testing/CustomListBullet";
import { BecomeMemberButton } from "./FamilyTree";
import Bubble from "../../../testing/Bubble";


const Wrap = styled.div`
  position: relative;
  overflow: hidden;
  padding: 5rem 0;
`;
const IntroContainer = styled(Container)`
  h2,
  h5,
  p {
    text-align: center;
    margin: auto;
  }
  h2 {
    color: ${Theme.hex('darkSecondary')};
    font-size: 40px;
    max-width: 31rem;
    margin-bottom: 1rem;
    b {
      font-weight: 800;
    }
  }
  h5 {
    color: ${colors.hex('secondary')};
    font-size: 24px;
    max-width: 34rem;
    margin-bottom: 2rem;
  }
  p {
    max-width: 48rem;
    margin-bottom: 5rem;
  }
`;
const CardContainer = styled(Container)``;
const CardWrap = styled.div`
  position: relative;
  max-width: 49rem;
  background: #F8F8F8;
  margin: auto;
  padding: 3rem 6rem;
  border-radius: 40px;
  h4 {
    color: ${colors.hex('primary')};
    margin-bottom: 2rem;
  }
  .content-list {
    .item {
      margin: 0 0 1rem -1.75rem;
      display: flex;
      .bullet-wrap {
        width: 22px !important;
        height: 2rem;
        margin-right: .5rem;
      }
    }
  }
  @media only screen and (max-width: 399px) {
    padding: 2rem 3rem;
  }
`
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`

const CopySection1 = ({ introJson, cardJson }) => {
  return (
    <Wrap>
      <IntroContainer>
        {introJson && documentToReactComponents(introJson)}
      </IntroContainer>
      <CardContainer>
        <CardWrap>
          {cardJson && documentToReactComponents(cardJson, options)}
          <Bubble bg={colors.hex('primary')} size="8rem" left="-7rem" top="25rem" zIndex={2} mobBreak="767px" mobTop="35rem" mobLeft="-6rem" />
          <Bubble bg={colors.hex('secondary')} size="4rem" left="-.5rem" top="28rem" zIndex={3} mobBreak="767px" mobTop="40rem" mobLeft="-2rem" />
        </CardWrap>
      </CardContainer>
      <ButtonWrap>
        <BecomeMemberButton />
      </ButtonWrap>
      <Bubble bg={colors.hex('secondary')} size="8rem" left="93vw" top="22rem" />
    </Wrap>
  );
};

export default CopySection1;


const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node) => {
      const { content } = node;
      // console.log("content in rtoptions: ", content)        
      return (
        content && (
          <div className="content-list">
            {content.map((item, i) => {
              // console.log("item: ", item)
              return (
                <div className="item" key={`list-item-${i}`}>
                  <div className="bullet-wrap">
                    <ListBullet darkBlue={true} scale={.8} />
                  </div>
                  <p>
                    {item.content[0].content[0].value}
                  </p>                    
                </div>
              )
            })}
          </div>
        )
      )
    }
  }
}