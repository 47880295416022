import * as React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import Theme from "../../../vars/ThemeOptions";
import HubspotForm from "react-hubspot-form";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Wrap = styled.div`
  background: ${colors.hex("secondary")};
  
`;
const formWidth = "600px";
const ContentContainer = styled(Container)`
display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5rem 1rem;
  h3 {
    color: ${Theme.hex("darkSecondary")};
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  p {
    font-weight: 600 !important;
    font-size: 17px;
    color: ${colors.hex("black")} !important;
    max-width: ${formWidth};
    letter-spacing: .5px;
    line-height: 28px;
    a {
      color: ${colors.hex("white")};
      text-decoration: underline;
      display: inline-block;
    }
  }
`
const FormWrap = styled.div`
  width: ${formWidth};
  @media only screen and (max-width: 767px) {
    width: 250px;
  }
`

const FormSection = ({ copyJson }) => {
  return (
    <Wrap id="form-section">
      <ContentContainer>
        {/* <h3>Get Peace of Mind</h3>
        <p>
          To get started, please fill out the form below and someone on our team
          will reach out for more information. Or if you’re ready to schedule a
          meeting today,{" "}
          <a
            href="https://willsandwellness.com/schedule-design-meeting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here >
          </a>
        </p> */}
        {copyJson && documentToReactComponents(copyJson)}
        <FormWrap>
          <HubspotForm
            portalId="7210399"
            formId="86060830-70c2-4742-944b-993471f7e906"
            onSubmit={() => console.log("Submit!")}
            onReady={(form) => console.log("Form ready!")}
            loading={<div>Loading...</div>}
          />
        </FormWrap>
      </ContentContainer>
    </Wrap>
  );
};

export default FormSection;
