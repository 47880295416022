import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import VidPlayer from './VidPlayer'
import VidList from './VidList'
import VidInfo from './VidInfo'
import PrevNext from './PrevNext'
import Comments from './Comments/Comments'
import NextPreview from './NextPreview'
// import BookSession from './BookSession'
// import Share from './Share'
 
const Wrap = styled.div`
  overflow: hidden;
  width: 100%;
`
 
const Videos = ({ vids, whiteArrow, blackArrow, nextVidBg, session, tab }) => {
  const vidArr = vids.edges.map(p => p.node)
  // console.log('vidArr: ', vidArr)

  const [currVid, setCurrVid] = useState(0)
  const updateCurrVid = ( currVidIndex, prevNext ) => {
    return(
      prevNext === "prev" && currVidIndex > 0
        ? setCurrVid(currVidIndex - 1)
        : prevNext === "next" && currVidIndex < (vidArr.length - 1)
          ? setCurrVid(currVidIndex + 1)
          : null
    )
  }
  const currVidData = vidArr[currVid]
  console.log("currVidData: ", currVidData)
  // comment form state
  const [subject, setSubject] = useState("");
  const [comment, setComment] = useState("");
  const [profaneSub, setProfaneSub] = useState(false);
  const [profaneCom, setProfaneCom] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false)
  const [submitErr, setSubmitErr] = useState(false);
  const [pullComments, setPullComments] = useState(false);
  const resetForm = (submission) => {
    submission ? setFormSuccess(true) : setFormSuccess(false)
    setSubmitErr(false)
    setSubject("")
    setProfaneSub(false)
    setComment("")
    setProfaneCom(false)
    setPullComments(!pullComments)
  }
  return (
    <Wrap>
      <Container>
        <Row>
          <Col sm={12} md={8}>
            {/* <VidPlayer title={currVidData.title} url={currVidData.videoFile.file.url} */}
            <VidPlayer title={currVidData.title} embedLink={currVidData.videoEmbedLink.videoEmbedLink} />
          </Col>
          <Col sm={12} md={4}>
            <VidList vids={vidArr} currVid={currVid} setCurrVid={setCurrVid} />
          </Col>
        </Row>
      </Container>
      {/* <Share tab={tab} /> */}
      <Container>
        <Row>
          <Col>
            <VidInfo vid={vidArr[currVid]} />
          </Col>
        </Row>
        <PrevNext vidArr={vids.edges.map(p => p.node)} currVid={currVid} updateCurrVid={updateCurrVid} whiteArrow={whiteArrow} blackArrow={blackArrow} resetForm={resetForm} />
        <Row>
          <Col>
            <Comments currVid={currVid} subject={subject} setSubject={setSubject} comment={comment} setComment={setComment} profaneSub={profaneSub} setProfaneSub={setProfaneSub} profaneCom={profaneCom} setProfaneCom={setProfaneCom} success={formSuccess} setSuccess={setFormSuccess} submitErr={submitErr} setSubmitErr={setSubmitErr} resetForm={resetForm} pullComments={pullComments} />
          </Col>
        </Row>
      </Container>
      <Container fluid style={{padding: 0}}>
        <Row>
          <Col>
            <NextPreview vids={vids} bg={nextVidBg} currVid={currVid} updateCurrVid={updateCurrVid} whiteArrow={whiteArrow} blackArrow={blackArrow} resetForm={resetForm}  />  
          </Col>
        </Row>
      </Container>
    </Wrap>
  )
}
 
export default Videos