import React from 'react'
import styled from 'styled-components'
import Circle from './Circle'
 
const Wrap = styled.div`
  width: 100%;
  display: flex;
  margin: 1.5rem 0;
  p {
    :first-of-type {
      font-size: 1.5rem;
      font-weight: 500;
      margin: -.2rem 0 .5rem 0;
    }
    :last-of-type {
      font-size: .9rem;
      font-weight: 300;
      line-height: 1.5rem;
    }
  }
`
 
const Comment = ({subject, comment, i}) => {
  return (
    <>
      {subject && comment &&
        <Wrap>
          <Circle color={i%3 === 0 ? "primary" : i%3 === 1 ? "accent" : "secondary" } />
          <div className="comment-wrap">
            <p>{subject}</p>
            <p>{comment}</p>
          </div>
        </Wrap>
      }
    </>
  )
}
 
export default Comment