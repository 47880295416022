import React from 'react'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import BookSession from '../VideosPage/Content/Videos/BookSession' 

const Wrap = styled.div`
  margin-top: 4rem;
  p {
    font-weight: 200;
  }
`
 
const BookSessionContent = ({ data }) => {
  return (
    <Wrap>
      {documentToReactComponents(data.richCopy2.json)}
      <BookSession />
    </Wrap>
  )
}
 
export default BookSessionContent