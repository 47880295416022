import React from 'react'
import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types';
import Theme from '../../../vars/ThemeOptions'
import ListBullet from '../../../testing/CustomListBullet';


 
const Wrap = styled(Col)`
  .content-list {
    .item {
      margin: 0 0 0 0;
      display: flex;
      .bullet-wrap {
        width: 22px !important;
        height: 2rem;
        margin-right: .7rem;
      }
      p {
        color: ${Theme.hex('black')} !important;
        font-size: 1rem !important;
        font-weight: 300 !important;
        line-height: 1.5rem !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        position: relative;
        top: 0 !important;
        padding: 0 !important;
        text-align: left !important;
      }
    }
  }
  h4 {
    color: ${Theme.hex('darkSecondary')};
    text-transform: uppercase;
    font-weight: bold;
  }
  h2 {
    text-transform: uppercase;
  }
  h2, h5 {
    color: ${Theme.hex('white')};
    margin-top: 1.5rem;
  }
  p {
    color: ${Theme.hex('white')};
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 2rem;
  }
  blockquote {
    margin-top: -2.5rem;
  }
  @media only screen and (max-width: 1199px) {
    h2 {
      margin-bottom: 24rem;
    }
    h5 {
      margin-top: 24rem;  
      color: ${Theme.hex('darkSecondary')}; 
    }
    p {
      text-align: center;
      position: relative;
      top: 3rem;
      font-size: 3rem;
      line-height: 3rem;
      padding: 0 10vw;
      margin-bottom: 3rem;
      color: ${Theme.hex('primary')};
    }
  }
`
 
const Row1Col1 = ({ richText }) => {
  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node) => {
        const { content } = node;
        // console.log("content in rtoptions: ", content)        
        return (
          content && (
            <div className="content-list">
              {content.map((item, i) => {
                // console.log("item: ", item)
                return (
                  <div className="item">
                    <div className="bullet-wrap">
                      <ListBullet darkBlue={true} />
                    </div>
                    <p>
                      {item.content[0].content[0].value}
                    </p>                    
                  </div>
                )
              })}
            </div>
          )
        )
      }
    }
  }
  return (
    <Wrap lg={12} xl={5}>
      {documentToReactComponents(richText.json, options)}
    </Wrap>
  )
}
 
export default Row1Col1