/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from 'gatsby';
import { MainLayout } from "wmk-lib";

import Header from "./LandingPages/Header";
import Footer from "./LandingPages/Footer";

const Layout = ({ children, noHeader, noFooter }) => {
  const headFootData = useStaticQuery(graphql`
    query {
      logo: contentfulAsset(title: {eq: "Logo"}) {
        fixed {
          ...GatsbyContentfulFixed
        }
      }
      tab: contentfulAsset(title: {eq: "Tab"}) {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)
  return (
    <>
      <MainLayout
        Header={() => noHeader ? null : <Header logo={headFootData.logo} tab={headFootData.tab} />}
        Footer={() => noFooter ? null :  <Footer /> }
      >
        {children}
      </MainLayout>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;