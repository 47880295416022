import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import Theme from "../../../vars/ThemeOptions";
import { BLOCKS } from '@contentful/rich-text-types';
import ListBullet from "../../../testing/CustomListBullet";
import Bubble from "../../../testing/Bubble";


const Wrap = styled.div`
  position: relative;
  overflow: hidden;
  padding: 5rem 0 7rem;
`;
const IntroContainer = styled(Container)`
  h2,
  h5,
  p {
    text-align: center;
    margin: auto;
  }
  h2 {
    color: ${Theme.hex('darkSecondary')};
    max-width: 33rem;
    margin-bottom: 1rem;
    b {
      font-weight: 800;
    }
  }
  h5 {
    color: ${colors.hex('secondary')};
    font-size: 20px;
    max-width: 20rem;
    margin-bottom: 2rem;
  }
  p {
    max-width: 34rem;
    margin-bottom: 5rem;
  }
`;
const CardContainer = styled(Container)``;
const CardWrap = styled.div`
  position: relative;
  max-width: 49rem;
  background: #F8F8F8;
  margin: auto;
  padding: 3rem 6rem;
  border-radius: 40px;
  h4 {
    color: ${colors.hex('primary')};
    margin-bottom: 2rem;
  }
  .content-list {
    .item {
      margin: 0 0 1rem 0;
      display: flex;
      .bullet-wrap {
        width: 22px !important;
        height: 2rem;
        margin-right: .5rem;
      }
    }
  }
  @media only screen and (max-width: 450px) {
    padding: 2rem 3rem;
  }
`

const CopySection2 = ({ introJson, cardJson }) => {
  return (
    <Wrap>
      <IntroContainer>
        {introJson && documentToReactComponents(introJson)}
      </IntroContainer>
      <CardContainer>
        <CardWrap>
          {cardJson && documentToReactComponents(cardJson, options)}
          <Bubble bg={colors.hex('secondary')} size="4rem" left="95%" top="-1.4rem" zIndex={2} />
          <Bubble bg={colors.hex('primary')} size="8rem" left="-5rem" top="61rem" zIndex={2} mobBreak="767px" mobTop="70rem" />
        </CardWrap>
      </CardContainer>
    </Wrap>
  );
};

export default CopySection2;


const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node) => {
      const { content } = node;
      // console.log("content in rtoptions: ", content)        
      return (
        content && (
          <div className="content-list">
            {content.map((item, i) => {
              // console.log("item: ", item)
              return (
                <div className="item" key={`list-item-${i}`}>
                  <div className="bullet-wrap">
                    <ListBullet darkBlue={true} scale={.8} />
                  </div>
                  <p>
                    {item.content[0].content[0].value}
                  </p>                    
                </div>
              )
            })}
          </div>
        )
      )
    }
  }
}