import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import HubspotForm from "react-hubspot-form";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Theme from "../../../../../vars/ThemeOptions";
import $ from "jquery";

const Wrap = styled.div`
  position: relative;
  overflow: hidden;
  .content {
    padding: 15rem 2vw 5rem 10vw;
    .centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .form-intro {
        padding: 0 0.5rem 0 0;
        h4 {
          font-size: 1.2rem;
        }
        p {
          font-size: 0.8rem;
        }
      }
      .form {
        width: 100%;
        form {
          margin-top: 0;
          width: 100%;
          position: relative;
          .field {
            width: 100%;
            margin-bottom: -15px;
            .input {
              width: 100%;
              position: relative;
              top: -25px;
              input {
                border: 2px solid ${Theme.hex("secondary")};
                padding: 3px 10px;
                width: 100%;
                color: ${Theme.hex("accent")};
                ::placeholder {
                  color: ${Theme.hex("secondary")};
                  font-style: italic;
                  font-weight: 300;
                }
                :focus {
                  outline: none !important;
                }
              }
            }
          }
          .hs-submit {
            position: absolute;
            right: 0;
            bottom: 30px;
            input {
              background: ${Theme.hex("primary")};
              border: none;
              color: white;
              padding: 5px 2rem;
              font-weight: 300;
            }
          }
          span.hs-form-required,
          ul.hs-error-msgs {
            display: none !important;
          }
          .hs-recaptcha {
            margin-top: 5px;
            position: relative;
            left: -87px;
            transform: scale(0.6);
          }
        }
      }
      .image-wrap {
        width: 100%;
        max-width: 40rem;
        // height: 100%;
        .gatsby-image-wrapper {
          // height: 50%;
        }
      }
    }
  }
  .bubble-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -10;
    .bubble {
      position: absolute;
      border-radius: 50%;
      &.orange {
        background: #f15c11;
      }
      &.teal {
        background: #8bc7d2;
      }
      &.bubble-1 {
        width: 50vw;
        height: 50vw;
        top: 10vw;
        left: -40vw;
      }
      &.bubble-2 {
        width: 20vw;
        height: 20vw;
        top: -3vw;
        left: 60vw;
      }
      &.bubble-3 {
        width: 10vw;
        height: 10vw;
        top: 11vw;
        left: 56vw;
      }
      &.bubble-4 {
        width: 70vw;
        height: 70vw;
        top: 40vw;
        left: 60vw;
      }
    }
  }
  // @media only screen and (min-width: 1500px) {
  //   .content .centered .image-wrap {
  //     width: 20rem !important;
  //   }
  // }
`;



const Form = ({ copy, vidImage, setUnlocked }) => {
  const [formUnlocked, setFormUnlocked] = useState(false);
  const [form, setForm] = useState();
  const formRef = useRef();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  useEffect(() => {
    // const timer = setTimeout(() => {
    //console.log("timeout", formRef);
    const theForm = formRef.current;
    setForm(theForm);
    // }, 5000);

    //return clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setUnlocked(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleForm);
    return () => {
      window.removeEventListener("message", handleForm);
    };
  }, []);
  
  const handleForm = (event) => {
    // console.log("event", event);
    if (event.data.eventName === "onFormSubmit") {
      if (event.data.id === "07cf71e1-0e4c-42f0-936f-ec01a09ff16d") {
        localStorage.setItem("hasCompletedForm", "true");
        setFormUnlocked(true);
      }
      if (event.data.data.length) {
        let first;
        let last;
        let mail;
        event.data.data.map((formData) => {
          if (formData.name === "firstname") {
            first = formData.value;
          } else if (formData.name === "lastname") {
            last = formData.value;
          } else if (formData.name === "email") {
            mail = formData.value;
          }
        });
        setUserData({
          firstName: first,
          lastName: last,
          email: mail,
        });
      }
    }
  };

  // console.log(form);

  const { fluid } = vidImage ? vidImage : null;
  const alt = vidImage && vidImage.title ? vidImage.title : null;
  return (
    <Wrap>
      <div className="content">
        <Container fluid>
          <Row id="form-row">
            <Col sm={12} md={5} className="centered">
              <div className="form-intro">
                {documentToReactComponents(copy.json)}
              </div>
              <div className="form">
                <HubspotForm
                  ref={formRef}
                  portalId="7210399"
                  formId="c6f4b22a-7a66-4b73-8923-5cdac9c5e4d7"
                  // this onReady function is required to make sure that jquery is actually loaded before running the onSubmit function - without this onReady, there was an error saying that the onSubmit fn requires jquery and was not run, despite having jquery package loaded and importing it in this file
                  onReady={() => {
                    const script = document.createElement("script");
                    script.src =
                      "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
                    let jqueryScript = document.getElementsByTagName("script");
                    let src = Array.from(jqueryScript).filter(
                      (item) => item.src === script.src
                    );
                    if (src.length === 0) {
                      document.body.appendChild(script);
                    }
                  }}
                  onSubmit={(values) => {
                    //setForm(formRef.current);
                    setTimeout(() => {
                      const { el } = form ? form : { el: null };
                      const action = el.getAttribute("action");
                      //console.log(action)
                      setUnlocked(true);
                      // Submit the form using AJAX.
                      //console.log(form)
                      $.ajax({
                        type: "POST",
                        url: action,
                        data: values,
                      }).then(() => {
                        window.location.reload();
                      });
                    }, 1000);
                  }}
                />
              </div>
            </Col>
            <Col sm={12} md={7} className="centered">
              <div className="image-wrap">
                <Img fluid={fluid} alt={alt} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bubble-bg">
        <div className="bubble teal bubble-1" />
        <div className="bubble teal bubble-2" />
        <div className="bubble orange bubble-3" />
        <div className="bubble orange bubble-4" />
      </div>
    </Wrap>
  );
};

export default Form;
