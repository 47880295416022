import React from 'react'
import styled from 'styled-components'
import Theme from '../../../../../vars/ThemeOptions'
import BookSession from './BookSession'
 
const Wrap = styled.div`
  width: 100%;
  h1 {
    color: ${Theme.hex('accent')};
    font-size: 2rem;
    font-weight: 600;
  }
  video {
    width: 100%;
  }
  .vid-div {
    width: 100%;
    height: 20rem;
  }
`
 
const VidPlayer = ({ title, url, embedLink }) => {
  return (
    <Wrap>
      <h1>Kim Talks: {title}</h1>
      {/* <video controls src={url} type="video/mp4" /> */}
      <div dangerouslySetInnerHTML={{__html: embedLink}} className="vid-div" />
      <div className="form-wrap">
        <BookSession />
      </div>
    </Wrap>
  )
}
 
export default VidPlayer