import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import Img from "gatsby-image";
import styled from "styled-components";

const Wrap = styled(Col)`
  position: relative;
  // note: basic link styling done in parent component (MomToMomToolkit.js)
  a {
    position: relative;
    left: 6rem;
  }
  @media only screen and (max-width: 1199px) {
    height: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    div {
      position: absolute;
      width: 100%;
      height: 100%;
      top: -10rem;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .gatsby-image-wrapper {
        width: 28rem;
        height: 19rem;
      }
      a {
        top: -10rem;
        left: 0rem;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    div {
      top: -12.5rem;
      a {
        top: -12.5rem;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    div {
      top: -15.5rem;
      a {
        top: -15.5rem;
      }
    }
  }
  @media only screen and (max-width: 350px) {
    div {
      top: -17.5rem;
      a {
        top: -17.5rem;
      }
    }
  }
`;

const Row1Col2 = ({ image, link, linkText }) => {
  const [dataLayerObj, setDataLayerObj] = useState();
  useEffect(() => {
    const globalDataLayer = window.dataLayer;
    if (globalDataLayer && typeof globalDataLayer === "object") {
      setDataLayerObj(globalDataLayer);
    }
  }, [linkText]);
  return (
    <Wrap lg={12} xl={7}>
      <div>
        <Img fluid={image.fluid} alt={image.title} />
        <a
          href={link}
          onClick={
            dataLayerObj
              ? () => {
                  dataLayerObj.push({
                    event: "pdfDownload",
                    pdfType: "momToMom",
                  });
                }
              : undefined
          }
        >
          {linkText}
        </a>
      </div>
    </Wrap>
  );
};

export default Row1Col2;
