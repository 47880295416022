import React, { useState, useEffect } from 'react'
import axios from '../../../../../axios'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import Theme from '../../../../../../vars/ThemeOptions'
import Form from './Form'
import Comment from './Comment'
 
const Wrap = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const WrapCont = styled(Container)`
  .form-row {    
    padding-bottom: 2rem;
  }
  h4 {
    margin-bottom: 2.5rem; 
  }
  .comments-row {
    border-top: 2px solid ${Theme.hex('secondary')};
    border-bottom: 2px solid ${Theme.hex('secondary')};
  }
  button {
    margin: 1rem 0 3rem 1rem;
    background: none;
    border: 1px solid ${Theme.hex('primary')};
    color: ${Theme.hex('primary')};
    transition: all .3s ease;
    :hover {
      background: ${Theme.hex('primary')};
      color: white;
      transition: all .3s ease;
    }
  }
  p.no-comms {
    width: 90%;
    text-align: center;
    margin: 2rem;
    font-size: 1.5rem;
  }
`
 
const Comments = ({ currVid, subject, setSubject, comment, setComment, profaneSub, setProfaneSub, profaneCom, setProfaneCom, success, setSuccess, submitErr, setSubmitErr, resetForm, pullComments }) => {
  const [comments, setComments] = useState(null)
  const [noComments, setNoComments] = useState(false)
  const [showMore, setShowMore] = useState(false)
  useEffect(() => {
    axios.get(`/vid-`+currVid+`-comments.json`)
      .then(res => formatRes(res)) 
      .catch( err => console.log(err))
  }, [currVid, pullComments])

  const formatRes = (res) => {
    let formattedComments = res && res.data && Object.entries(res.data)
    formattedComments ? setNoComments(false) : setNoComments(true)
    formattedComments 
      ? setComments(formattedComments.sort(function(a,b) {
          return new Date(b[1].date) - new Date(a[1].date)
        }))
      : setComments(null)
  }

  return (
    <Wrap>
      <WrapCont>
        <Row className="form-row">
          <Col>
            <h4>Comments</h4>
            <Form currVid={currVid} subject={subject} setSubject={setSubject} comment={comment} setComment={setComment} profaneSub={profaneSub} setProfaneSub={setProfaneSub} profaneCom={profaneCom} setProfaneCom={setProfaneCom} success={success} setSuccess={setSuccess} submitErr={submitErr} setSubmitErr={setSubmitErr} resetForm={resetForm} />
          </Col>
        </Row>
        <Row className="comments-row">
          <Col>
            {comments && comments.map((comm, i) => {
              // console.log("comments: ", comments)
              return i < 5 && <Comment subject={comm[1].subject} comment={comm[1].comment} i={i} key={`comment`+i} />
            })}
            {showMore && comments && comments.map((comm, i) => {
              return i >= 5 && <Comment subject={comm[1].subject} comment={comm[1].comment} i={i} key={`comment`+i} />
            })}
            {comments !== null && comments.length > 5 &&
              <button onClick={() => setShowMore(!showMore)} label="show more or less comments">
                Show {showMore ? "Less" : "More"} Comments
              </button>
            }
            {noComments && <p className="no-comms">Be the first to comment on this video!</p>}
          </Col>
        </Row>
      </WrapCont>
    </Wrap>
  )
}
 
export default Comments