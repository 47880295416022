import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import Theme from '../../../vars/ThemeOptions'
import Img from 'gatsby-image'
//import { WMKLink } from "wmk-lib";
 
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .image-wrap {
    height: 20rem;
    width: 17rem;
    position: relative;
    a {
      :hover {
        text-decoration: none;
      }
      .rec {
        height: 19rem;
        width: 16rem;
        box-shadow: 1px 1px 10px #b5b5b5;
        background: white;
        &.rec-1 {
          height: 19rem;
          width: 16rem;
          position: relative;
          z-index: 5;                
          .gatsby-image-wrapper {
            height: 100%;
          }
          // p {
          //   color: ${Theme.hex('accent')};
          //   font-size: 5rem;
          //   font-weight: 500;
          //   font-style: italic;
          // }
        }
        &.rec-2 {
          position: absolute;
          z-index: 0;
          top: 1rem;
          left: 1rem;
        }
      }
    }
  }
  .button-wrap {
    margin-top: 2.5rem;
    a {
      background: ${Theme.hex('primary')};
      color: white;
      padding: 1rem 3.5rem;
      opacity: 1;
      transition: opacity .3s ease;
      :hover {
        text-decoration: none;
        opacity: .9;
        transition: opacity .3s ease;
      }
    }
  }
`
 
const STGDownload = ({ data, preview }) => {
  const [dataLayerObj, setDataLayerObj] = useState();
  // console.log("data in download: ", data)
  const { file } = data ? data : null
  const { url } = file ? file : null
  const { fluid } = preview ? preview : null
  const alt = preview && preview.title ? preview.title : null
  useEffect(() => {
    const globalDataLayer = window.dataLayer;
    if (globalDataLayer && typeof globalDataLayer === "object") {
      setDataLayerObj(globalDataLayer);
    }
  }, [data]);
  return (
    <Wrap>
      <div className="image-wrap">
        <a 
          href={url} 
          target="_blank" 
          rel="noopener noreferrer"
          onClick={
            dataLayerObj
              ? () => {
                  dataLayerObj.push({
                    event: "pdfDownload",
                    button: "pdfType",
                    campaign: "shortTermGuardian",
                  });
                }
              : undefined
          }
          >
          <div className="rec rec-1">
            <Img fluid={fluid} alt={alt} />
            {/* <p>PDF</p> */}
          </div>
          <div className="rec rec-2" />
        </a>
      </div>
      <div className="button-wrap">
        <a           
          href={url} 
          target="_blank" 
          rel="noopener noreferrer"
          onClick={
            dataLayerObj
              ? () => {
                  dataLayerObj.push({
                    event: "pdfDownload",
                    pdfType: "shortTermGuardian",
                  });
                }
              : undefined
          }>
          DOWNLOAD HERE
        </a>
      </div>
    </Wrap>
  )
}
 
export default STGDownload