import * as React from 'react';
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash/get'
import Hero from './Hero';
import CopySection1 from './CopySection1';
import InteractiveGraphic from './InteractiveGraphic';
import CopySection2 from './CopySection2';
import FormSection from './FormSection';
import Theme from '../../../vars/ThemeOptions';
 
const StyledAnchor = styled.a`
  display: inline-block;
  background: ${Theme.hex("secondary")};
  color: ${Theme.hex("darkSecondary")};
  font-weight: bold;
  font-size: 20px;
  padding: .5rem 2rem;
  border-radius: 10px;
  transform: scale(1);
  transition: all .3s ease;
  :hover {
    text-decoration: none;
    color: ${Theme.hex("darkSecondary")};
    transform: scale(1.05);
    transition: all .3s ease;
  }
  @media only screen and (max-width: 300px) {
    font-size: 18px;
    padding: .3rem 1rem;
  }
`
export const BecomeMemberButton = () => (
  <StyledAnchor href="#form-section">Become A Member Today</StyledAnchor>
)


const Wrap = styled.div`
  overflow: hidden;
  p {
    color: #616161;
    font-weight: 300;
  }
  b {
    font-weight: 600;
  }
`
 
const FamilyTreeLP = props => {
  const pageData = useStaticQuery(graphql`
    query {
      mainContent: contentfulGlobal(title: {eq: "Family Tree LP - Main Content"}) {
        ...NodeGlobalFields
      }
      graphicAndFormCopy: contentfulGlobal(title: {eq: "Family Tree LP - Intro Copy for Interactive Graphic and Form Section"}) {
        ...NodeGlobalFields
      }
      personas: allContentfulGlobal(filter: {title: {regex: "/Family Tree LP - Persona -/"}}) {
        edges {
          node {
            ...NodeGlobalFields
          }
        }
      }
      logo: contentfulAsset(title: {eq: "Logo"}) {
        fixed {
          ...GatsbyContentfulFixed
        }
        file {
          url
        }
      }
    }
  `)
  // console.log('pageData: ', pageData);
  const mainContent = get(pageData, "mainContent");
  const images = get(mainContent, "files");
  const imageFamily = images[0];
  const imageTreeGrayBlue = images[1];
  const imageTreeWhite = images[2];
  const section1IntroJson = get(mainContent, "richCopy1.json");
  const section1CardJson = get(mainContent, "richCopy2.json");
  const section2IntroJson = get(mainContent, "richCopy3.json");
  const section2CardJson = get(mainContent, "richCopy4.json");
  const heroCopyArr = [get(mainContent, "text1"), get(mainContent, "text2"), get(mainContent, "text3")];
  const personas = get(pageData, "personas") && get(pageData, "personas").edges.map(e=>e.node);
  const logo = get(pageData, "logo");
  const graphicCopy = get(pageData, "graphicAndFormCopy.richCopy1.json");
  const formCopy = get(pageData, "graphicAndFormCopy.richCopy2.json");
  return (
    <Wrap>
      <Hero imageFamily={imageFamily} imageTree={imageTreeWhite} copyArr={heroCopyArr} logo={logo} />
      <CopySection1 introJson={section1IntroJson} cardJson={section1CardJson} />
      <InteractiveGraphic personas={personas} imageTree={imageTreeGrayBlue} copyJson={graphicCopy} />
      <CopySection2 introJson={section2IntroJson} cardJson={section2CardJson} />
      <FormSection copyJson={formCopy} />
    </Wrap>
  )
}
 
export default FamilyTreeLP