import React from "react";
import Layout from "../components/layout/LPsLayout";
// import styled from 'styled-components'
import Videos from "../components/routes/VideosPage/VideosPage";
import ShortTermGuardian from "../components/routes/ShortTermGuardian/ShortTermGuardian";
import MomToMomToolkit from "../components/routes/MomToMomToolkit/MomToMomToolkit";
import { SEO } from "wmk-lib";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import FamilyTreeLP from "../components/routes/FamilyTree/FamilyTree";

// import {
//   HubUserContext,
//   isValidUser,
// } from "../components/context/HubspotContext";

// const NotValidWrap = styled.div`
//   width: 100vw;
//   height: 10rem;
//   padding: 5vw 10vw;
//   text-align: center;
// `

export default ({ pageContext }) => {
  // const blocks = data.wordpressPage.acf ? data.wordpressPage.acf.block_page : null;
  // const yoast = data.wordpressPage.yoast_meta
  let RenderThis = null;
  let noHeader = false;
  let noFooter = false;
  const route = pageContext.slug;
  switch (route) {
    case "videos-on-demand":
      RenderThis = <Videos />;
      break;
    case "short-term-guardian":
      RenderThis = <ShortTermGuardian />;
      break;
    case "mom-to-mom-toolkit":
      RenderThis = <MomToMomToolkit />;
      break;
    case "family-tree":
      RenderThis = <FamilyTreeLP />;
      noHeader = true;
      noFooter = true;
      break;
    default:
      RenderThis = (
        <h2>
          No Page template for <em>{pageContext.slug}</em> route!{" "}
          {console.log(pageContext)}
        </h2>
      );
  }
  return (
    <Layout noHeader={noHeader} noFooter={noFooter}>
      <SEO
        title={pageContext.title}
        path="/"
        slug={pageContext.slug}
        //description={desc}
        ogImage={null}
        twitterImage={null}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      {RenderThis}
    </Layout>
  );
};

// old return content
// const oldReturn = `
// <Layout>
// <HubUserContext.Consumer>
//   {({ user }) => {
//     console.log(user);
//     return isValidUser(user)
//       ? RenderThis
//       : <NotValidWrap>
//           <p>
//             <em>Sorry, you are not recognized as having filled out the form.</em>
//           </p>
//           <p>
//             <em>Please go back or <a href="https://wills-and-wellness.netlify.app/videos-on-demand">click here</a> and fill out the form to get access to Kim's videos.</em>
//           </p>
//         </NotValidWrap>;
//   }}
// </HubUserContext.Consumer>
// </Layout>
// `
