import * as React from 'react';
import get from 'lodash/get'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components'
import { BecomeMemberButton } from './FamilyTree';
import Bubble from '../../../testing/Bubble';
import { colors } from '../../../vars/palette';
 
const Wrap = styled.div`
  position: relative;
  height: 550px;
  padding: 3rem 0 5rem;
  background: #F8F8F8;
  // background: ${colors.hex('white')};
  * {
    position: relative;
    z-index: 1;
  }
  h3 {
    color: ${colors.hex('secondary')};
    font-weight: bold;
    font-size: 32px;
    margin-bottom: .1rem;
  }
  h1 {
    color: ${colors.hex('primary')};
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 1rem;
  }
  p {
    max-width: 20rem;
    line-height: 28px;
    margin-bottom: 1.5rem;
  }
  @media only screen and (max-width: 350px) {
    h3 {
      font-size: 25px;
    }
    h1 {
      font-size: 38px;
    }
  }
`
const LogoAnchor = styled.a`
  display: inline-block;
  margin-bottom: 3rem;
`

const BgWrap = styled.div`
  overflow: hidden;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 111%;
`
const TreeWrap = styled.div`
  position: relative;
  z-index: 1;
  img {
    position: relative;
    z-index: 1;
    left: 32vw;
    bottom: -1rem;
    width: 37rem;
  }
  @media only screen and (min-width: 2150px) {
    img {
      left: 50vw;
    }
  }
  @media only screen and (max-width: 1399px) {
    img {
      left: 20vw;
    }
  }
  @media only screen and (max-width: 991px) {
    img {
      left: 45vw;
    }
  }
  @media only screen and (max-width: 767px) {
    img {
      left: 22vw;
    }
  }
  @media only screen and (max-width: 576px) {
    img {
      left: 5vw;
    }
  }
`
// Family image shows straight right edge starting at about 2150px (with the current positioning settings)
const FamilyWrap = styled.div`
  position: relative;
  z-index: 2;
  margin: 0;
  left: 66vw;
  top: -55rem;
  width: 55rem;
  height: 55rem;
  border-radius: 50%;
  overflow: hidden;
  @media only screen and (min-width: 2150px) {
    display: none;
  }
  @media only screen and (max-width: 1799px) {
    left: 60vw;
  }
  @media only screen and (max-width: 1399px) {
    left: 50vw;
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
  img {
    position: relative;
    bottom: -17rem;
    left: 0;
    width: 45rem;
  }
  .white-grad {
    position: relative;
    left: -9.55rem;
    bottom: -2rem;
    width: 45rem;
    height: 10rem;
    transform: rotate(45deg);
    background: linear-gradient(to top, rgba(254, 254, 254, 1) 0%, rgba(254, 254, 254, 0) 100%);
    // border: 2px solid black;
  }
`
 
const Hero = ({ imageFamily, imageTree, copyArr, logo }) => {
  const ww = copyArr[0];
  const pageTitle = copyArr[1];
  const subtitle = copyArr[2];
  return (
    <Wrap>
      <Container>
        <Row>
          <Col lg={5} md={9} xs={12}>
            <LogoAnchor href="https://willsandwellness.com/" target='_blank' rel='noopener noreferrer'>
              <img src={get(logo, "file.url")} alt="Wills & Wellness Logo" />
            </LogoAnchor>
            <h3>{ww}</h3>
            <h1>{pageTitle}</h1>
            <p>{subtitle}</p>
            <BecomeMemberButton />
          </Col>
        </Row>
      </Container>
      <BgWrap>
        <TreeWrap>
          <img src={get(imageTree, "file.url")} alt="tree digital art" />
        </TreeWrap>
        <FamilyWrap>
          <img src={get(imageFamily, "file.url")} alt="happy family on a beach" />
          <div className='white-grad' />
        </FamilyWrap>
        <Bubble bg={colors.hex('primary')} size="9rem" left="93vw" top="-62rem" zIndex="0" />
      </BgWrap>
    </Wrap>
  )
}
 
export default Hero